
import {ReactComponent as AmazonLogo} from '../images/amazon.svg'
import {ReactComponent as GoogleAdsLogo} from '../images/googleads.svg'
import {ReactComponent as LinkedinAdsLogo} from '../images/linkedinads.svg'
import {ReactComponent as MicrosoftAdsLogo} from '../images/microsoftads.svg'
import {ReactComponent as HubSpotLogo} from '../images/hubspot.svg'
import {ReactComponent as FacebookLogo} from '../images/facebookads.svg'
import {ReactComponent as WooLogo} from '../images/woocommercelogo.svg'
import {ReactComponent as BolLogo} from '../images/bollogo2.svg'



export default function LogoJM() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-6 md:grid-cols-6 lg:grid-cols-8">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <AmazonLogo className='h-12' />
            
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <GoogleAdsLogo className='h-12' />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <LinkedinAdsLogo className='h-12' />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <MicrosoftAdsLogo className='h-12' />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <HubSpotLogo className='h-12' />
          </div>
                    <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <FacebookLogo className='h-12' />
          </div>
        <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <WooLogo className='h-14' />
          </div>
        <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <BolLogo className='h-14 w-24  md:w-96' />
          </div>


        </div>
      </div>
    </div>
  )
}
