import en from './en/ns1.json';
import es from './es/ns2.json';
import fr from './fr/ns3.json'
import nl from './nl/ns4.json';
import nlfeatures from './nl/nlfeatures';
import frFeatures from './fr/frfeatures';
import enFeatures from './en/enfeatures';
import deFeatures from './es/defeatures';

export const translate = (key: string, language: string): string => {
  let langData: { [key: string]: string } = {};

  if(language === 'EN') {
    langData = en;
  }
  else if(language === 'DE') {
    langData = es;
  }else if(language === 'FR') {
    langData = fr;
  }
  else if(language === 'NL') {
    langData = nl;
  }

  return langData[key];
}


export const translateFeatures = (key: string, language: any): any => {
  let featurelangData: { [key: string]: any } = {};
    if(language === 'EN') {
    featurelangData = enFeatures;
  }
  else if(language === 'DE') {
    featurelangData = deFeatures;
  }
  else if(language === 'FR') {
    featurelangData = frFeatures;
  }
    else if(language === 'NL') {
    featurelangData = nlfeatures;
  }

  return featurelangData[key];
}
