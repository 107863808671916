import {ReactComponent as Bargraph} from '../svgs/bargraph.svg'
import {ReactComponent as Arrowchart} from '../svgs/arrowchart.svg'
import {ReactComponent as LaptopPhone} from '../svgs/laptopphone.svg'
import {ReactComponent as CloudOpslag} from '../svgs/cloudicon.svg'
import {ReactComponent as SalesIcon} from '../svgs/sales.svg'
import {ReactComponent as AlarmIcon} from '../svgs/alarmcheck.svg'

const enFeatures = 

{ 
    "features" : [{ 

        'title' : 'Gain valuable insights',
        'subtitle' : 'Get an overview of your business',
        'svg' : <Bargraph/>    
    },

    {

    'title' : 'Save Time',
    'subtitle' : 'Focus on what matters',
    'svg' : <AlarmIcon/>
    
    },
{
    'title' : 'Improved decision-making',
    'subtitle' : 'Based on data',
    'svg' : <Arrowchart/>
    
    }, {
    'title' : 'Any device',
    'subtitle' : 'Desktop, mobile, tablet. Anywhere',
    'svg' : <LaptopPhone/>
    
    }, {
    'title' : '24/7 Access',
    'subtitle' : 'Access your data anywhere, anytime',
    'svg' : <CloudOpslag/>
    
    }, {
    'title' : 'Get Results',
    'subtitle' : 'Let data work for you',
    'svg' : <SalesIcon/>
    
    }

    
    ],

    

}
export default enFeatures