import React, { Fragment, useEffect  } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch,  useSelector } from 'react-redux';
import Contenttabs from '../components/contentsection/contenttabs';
import { translate } from './../locales/index';
import { translateFeatures } from './../locales/index';
import { RootState } from '../store';
import FixedHero from '../components/heros/fixedhero';
import Features from '../components/features/featurecomp';
import Testimonial from '../components/testimonials/testimonial';
import ContactFormAlt from '../components/contact/contact-form-alt';
import CenterCta from '../components/cta/centercta';
import NavBarAlt from '../components/nav/navalt';
import { setLanguage } from '../store/actions/langActions';
import enTiers from './../locales/en/entiers.json'
import nlTiers from './../locales/nl/nltiers.json'
import deTiers from './../locales/es/detiers.json'
import frTiers from './../locales/fr/frtiers.json'
import enNavigation from './../locales/en/ennavigation.json';
import nlNavigation from './../locales/nl/nlnavigation.json';
import deNavigation from './../locales/es/denavigation.json';
import frNavigation from './../locales/fr/frnavigation.json';
import MultilangFooter from '../components/footer/multilangfooter';
import { Helmet } from 'react-helmet-async';

import '../App.css';
import PricingCardMultilang from '../components/pricing/pircesectionmultilang';

const HomePage = () => {
  

  const dispatch = useDispatch();
  const { lang } = useParams<{ lang: string }>();
  const { language } = useSelector((state: RootState) => state.lang);

  let colorPicker = true 
  // Set the language based on the URL parameter
  useEffect(() => {
    if (lang === 'en' || lang === 'fr' || lang === 'nl' || lang === 'de') {
      dispatch(setLanguage(lang.toUpperCase()));
    }
  }, [lang, dispatch]);
  const getPriceTiers = () => {
  switch (language) {
    case 'EN':
      return enTiers;
    case 'NL':
      return nlTiers;
    case 'DE':
      return deTiers;
    case 'FR':
      return frTiers;
    // Handle translations for other languages if needed
    default:
      return enTiers; // Fallback to English translations
  }
};

const getNavigation = () => {
  switch (language) {
    case 'EN':
      return enNavigation;
    case 'NL':
      return nlNavigation;
    case 'DE':
      return deNavigation;
    case 'FR':
      return frNavigation;
    // Handle translations for other languages if needed
    default:
      return enNavigation; // Fallback to English translations
  }
};

  const navigation = getNavigation();
    const priceTiers = getPriceTiers();
  const yearlyTiers = priceTiers.monthlyTiers.map(tier => ({ ...tier, priceMonthly: tier.priceYearly, priceYearly: tier.priceYearly * 10 }));


  return(

    <Fragment>
            <section className="intro overflow-hidden">
        <div className="intro__overlay"></div>
        <div className="intro__body">
           <Helmet>
          <title>Business-Intelligence</title>
</Helmet>
    < NavBarAlt/>
    
        </div>
      </section>
      <section > 

               <FixedHero
        title= {translate('home', language)}
        titleSub={translate('about', language)}
        firstParagraph= {translate('introText', language)}
        secondParagraph= {translate('aboutUsText', language)} 
        buttonCTA= 'Dashboards'
        buttonAhref='dashboards'
        lowerButtonCta= {translate('managementCta', language)}
        mainColor = {colorPicker}
         />

      </section>
      <section>
        
                        <Contenttabs 
                        tagline= {translate('tagline', language)}
        firstTabTitle={"Management Dashboard"}
        secondTabTitle={"Stakeholder Dashboard"}
        thirdTabTitle={"E-commerce Dashboard"}


  managementMainTitle = {translate('managementMainTitle', language)}
  managementSubtitle = {translate('managementSubtitle', language)}
  managementTitleOne  = {translate('managementTitleOne', language)}
  managementContentOne  = {translate('managementContentOne', language)}
  managementTitleTwo  = {translate('managementTitleTwo', language)}
  managementContentTwo  = {translate('managementContentTwo', language)}
  managementTitleThree  = {translate('managementTitleThree', language)}
  managementContentThree  = {translate('managementContentThree', language)}
  managementTitleFour  = {translate('managementTitleFour', language)}
  managementContentFour  = {translate('managementContentFour', language)}
  managementTitleFive  = {translate('managementTitlteFive', language)}
  managementContentFive  = {translate('managementContentFive', language)}
  managementCtaTitle= {translate('genericTitle', language)}
  managementCtaSubtitle= {translate('genericSubtitle', language)}
    managementCta  = {translate('genericCTA', language)}
  
        stakeholderMainTitle={translate('stakeholderMainTitle', language)}
        stakeholderSubtitle={translate('stakeholderSubtitle', language)}
        stakeholderTitleOne={translate('stakeholderTitleOne', language)}
        stakeholderContentOne={translate('stakeholderContentOne', language)}
        stakeholderTitleTwo={translate('stakeholderTitleTwo', language)}
        stakeholderContentTwo={translate('stakeholderContentTwo', language)}
        stakeholderTitleThree={translate('stakeholderTitleThree', language)}
        stakeholderContentThree={translate('stakeholderContentThree', language)}
        stakeholderCta={translate('stakeholderCta', language)}

        marketingMainTitle={translate('marketingMainTitle', language)}
        marketingSubtitle={translate('marketingSubtitle', language)}
        marketingPone= {translate('marketingPone', language)}
        marketingPtwo= {translate('marketingPtwo', language)}
        marketingPthree= {translate('marketingPthree', language)}
        marketingPfour= {translate('marketingPfour', language)}
        marketingLiOne= {translate('marketingLiOne', language)}
        marketingLiTwo= {translate('marketingLiTwo', language)}
        marketingLiThree= {translate('marketingLiThree', language)}
        marketingLiFour= {translate('marketingLiFour', language)}
        marketingCtaTitle= {translate('genericCTA', language)}

        specialColor={colorPicker}


                  />
        </section>

        <section>

        <Features title= {translate('FeatureSectionTitle', language)}
        subtitle=  {translate('FeatureSectionSubtitle', language)}
        bigtitle= {translate('FeatureSectionBigtitle', language)}
        fdata = {translateFeatures('features', language)}
        specialColor = {colorPicker}
        />
                    <CenterCta
          ctaTitle=""
          ctaShortsub= {translate('genericTitle', language)}
          ctaLongsub= {translate('genericSubtitle', language)}
          ctaButton= {translate('genericCTA', language)}
        />
        <Testimonial />
        <section id="pricing"> 
        <PricingCardMultilang
        smallTitle = {priceTiers.smallTitle}
        startCTA = {priceTiers.startCTA}
        subCTA={priceTiers.subCTA}
        monthlyTiers={priceTiers.monthlyTiers}
        yearlyTiers={yearlyTiers}
        frequencies={priceTiers.frequencies}
        />
        </section>
        <ContactFormAlt 
        contactCta = {translate('contactCta', language)}
        FirstName = {translate('FirstName', language)}
        LastName = {translate('LastName', language)}
        company = {translate('Company', language)}
        email = {translate('Email', language)}
        phonenumber = {translate('Phone', language)}
        interest = {translate('interest', language)}
        message = {translate('message', language)}
        privacy = {translate('privacy', language)}
        thankYouMessage = {translate('thankYouMessage', language)}
        thankYouSubMessage = {translate('thankYouSubMessage', language)}
        />
        </section>
        <MultilangFooter navigation={navigation}/>
       </Fragment>
  );
}

export default HomePage;

