/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/

import {ReactComponent as Stakeholder} from '../images/stakeholders.svg'


type contentProps = {
  tabThreeMainTitle: string;
  tabThreeSubtitle: string;
  tabThreeTitleOne : string;
  tabThreeContentOne : string;
  tabThreeTitleTwo : string;
  tabThreeContentTwo : string;
  tabThreeTitleThree : string;
  tabThreeContentThree : string;
  tabThreeTitleFour : string;
  tabThreeContentFour : string;
  tabThreeTitleFive : string;
    tabThreeContentFive : string;

  
  tabThreeCta : string;
} 
export default function ThirdTabContent( {tabThreeMainTitle, tabThreeSubtitle, tabThreeTitleOne, tabThreeContentOne, tabThreeTitleTwo, tabThreeContentTwo, tabThreeTitleThree, tabThreeContentThree, tabThreeTitleFour, tabThreeContentFour, tabThreeTitleFive, tabThreeContentFive, tabThreeCta} : contentProps) {
  return (
    <div className="overflow-hidden bg-gray-50 py-16">
      <div className="mx-auto max-w-7xl sm:max-w-xl space-y-8 px-4 sm:px-4 lg:px-8">
        <div className="mx-auto max-w-prose text-base lg:max-w-none">
          <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            {tabThreeMainTitle} 
          </p>
        </div>
        <div className="relative z-10 mx-auto max-w-prose lg:mx-0 text-neutral lg:max-w-5xl lg:pr-72">
          <p className="text-lg text-gray-900">
            {tabThreeSubtitle}
          </p>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-8">
          <div className="relative z-10">
            <div className="prose prose-indigo mx-auto text-neutral lg:max-w-none">
              <h3 className='text-gray-900'>{tabThreeTitleOne}</h3>
              <p>
{tabThreeContentOne}              </p>
              <h3 className='text-gray-900'>{tabThreeTitleTwo}</h3>
              <p>
{tabThreeContentTwo}
              </p>
                            <div className="rounded-md shadow">
                <a
                  href="#contact"
                  className="flex w-full items-center no-underline justify-center rounded-md border border-transparent bg-base-100 px-8 py-3 text-base font-medium text-white hover:bg-neutral md:py-4 md:px-10 md:text-lg"
                >
                  {tabThreeCta}
                </a>
              </div>

              <h3 className='text-neutral'>{tabThreeTitleThree}</h3>
              <p className='text-gray-900'>
{tabThreeContentThree}              </p>

              <h3 className='text-neutral'>{tabThreeTitleFour}</h3>
              <p className='text-gray-900'>
{tabThreeContentFour}              </p>
              <h3 className='text-neutral'>{tabThreeTitleFive}</h3>
              <p className='text-gray-900'>
{tabThreeContentFive}              </p>

            </div>
            <div className="mx-auto sm:hidden  sm:pb-0 lg:relative lg:py-4 max-w-prose text-base lg:max-w-none">
              <div className="rounded-md shadow">
                <a
                  href="#contact"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-base-100 px-8 py-3 text-base font-medium text-white hover:bg-neutral md:py-4 md:px-10 md:text-lg"
                >
                  {tabThreeCta}
                </a>
              </div>
            </div>
          </div>
          <div className="lg:flex sm:flex  sm:my-0 sm:h-auto sm:mx-0 sm:-pt-72 md:pt-0  mx-auto mt-12 lg:mt-0  md:h-96 lg:max-w-none">
                  <Stakeholder />
          </div>
                      <div className="mx-auto md:mt-48 sm:-mt-12 sm:pb-0 lg:hidden   text-base lg:max-w-none">
              <div className="rounded-md shadow">
                <a
                  href="#contact"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-base-100 px-8 py-3 text-base font-medium text-white hover:bg-neutral md:py-4 md:px-10 md:text-lg"
                >
                  {tabThreeCta}
                </a>
              </div>
            </div>

        </div>
      </div>
    </div>
  )
}
