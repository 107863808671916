import {ReactComponent as Bargraph} from '../svgs/bargraph.svg'
import {ReactComponent as Arrowchart} from '../svgs/arrowchart.svg'
import {ReactComponent as LaptopPhone} from '../svgs/laptopphone.svg'
import {ReactComponent as CloudOpslag} from '../svgs/cloudicon.svg'
import {ReactComponent as SalesIcon} from '../svgs/sales.svg'
import {ReactComponent as AlarmIcon} from '../svgs/alarmcheck.svg'

const deFeatures = 

{ 
    "features" : [{ 

        'title' : 'Gewin wertvolle Erkenntnisse',
        'subtitle' : 'Verschaf einen Überblick über Ihr Business',
        'svg' : <Bargraph/>    
    },

    {

    'title' : 'Zeit sparen',
    'subtitle' : 'Konzentration auf das Wesentliche',
    'svg' : <AlarmIcon/>
    
    },
{
    'title' : 'Verbesserte Entscheidungsfindung',
    'subtitle' : 'Basierend auf Daten',
    'svg' : <Arrowchart/>
    
    }, {
    'title' : 'Jedes Gerät',
    'subtitle' : 'Desktop, Handy, Tablet. Überall',
    'svg' : <LaptopPhone/>
    
    }, {
    'title' : '24/7 Zugang',
    'subtitle' : 'Jederzeit und überall Zugriff auf Ihre Daten',
    'svg' : <CloudOpslag/>
    
    }, {
    'title' : 'Ergebnisse erhalten',
    'subtitle' : 'Lassen Sie Daten für sich arbeiten',
    'svg' : <SalesIcon/>
    
    }

    
    ],

    

}
export default deFeatures