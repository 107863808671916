import MobilePhone from "./mobilephone"

import React, { useState, useEffect } from 'react';

export default function Testimonial() {
  const [iframe, setIframe] = useState<JSX.Element | null>(null);

  useEffect(() => {
    if (window.innerWidth > 768) { // Adjust this value based on what you consider "mobile"
      setIframe(
        <iframe
          title="Report Section"
          className="absolute inset-0 w-full h-full"
          src="https://app.powerbi.com/view?r=eyJrIjoiNDA1MzVlNGMtOGVmNS00OTAwLWEyOWItNjZiNjY2ODEyMjQ1IiwidCI6IjM1ODUzN2M4LTUyYTQtNDQ3ZS05ZDQ3LWIyMTVhM2ViMzA1YiIsImMiOjh9&pageName=ReportSectiond8dbc5aee64120130454">
        </iframe>
      );
    }
    else {
      setIframe(<MobilePhone />);
    }
  }, []);

  return (
    <section className="overflow-hidden bg-gray-50 sm:py-2 lg:pt-12">
      <div className="relative mx-auto  lg:max-w-7xl px-4 mt-6 sm:px-6 lg:px-8">
        <div className="flex justify-center">
          <div className="relative lg:w-full" style={{ paddingBottom: '56.25%' }}>
            {iframe}
          </div>
        </div>
      </div>
    </section>
  )
}
