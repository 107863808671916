import {ReactComponent as MainHero} from '../images/main_team_v2.svg'
import {ReactComponent as SecondHero } from '../images/heroimagev2.svg'
import {ReactComponent as BlueHero } from '../images/heroimagev2alt.svg'
import {ReactComponent as BlueHeroMirror } from '../images/heroimagev3altv2.svg'
import { ReactComponent as LightVioletMirror } from '../images/heroimagev4.svg'

type HeroProps = {
  title: string;
  titleSub: string;
  firstParagraph: string;
  secondParagraph: string;
  buttonAhref: string;
  buttonCTA: string; 
  mainColor: Boolean; 
  lowerButtonCta: string;
}

export default function FixedHero({title, titleSub, firstParagraph, secondParagraph, buttonCTA, buttonAhref, lowerButtonCta, mainColor} : HeroProps) {
  let heroImage = <LightVioletMirror className="lg:absolute relative inset-0 h-full w-full object-cover"/>
  let textColor = "base-100"
  let textSecondarycolor = "neutral"
  let buttonMainColor = "neutral"
  
  if (mainColor != true){
   heroImage = <SecondHero className="lg:absolute relative inset-0 h-full w-full object-cover" />
   textColor = "warning"
   textSecondarycolor = "base-100"
   buttonMainColor = "warning"
  }
  return (
    <div className="relative bg-gray-50 min-h-screen">


      <main className="lg:relative">
        <div className="mx-auto w-full max-w-7xl pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
            <h1 className={"text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl text-" + textColor}>
              <span className="block xl:inline" >{title.split(" ")[0]} <span className={"italic text-" + textSecondarycolor}> {title.split(" ")[1]}</span></span>{' '}
              <span className={"block text-7xl:inline text-" + textSecondarycolor}> 
              <span className="italic">{titleSub.split(" ")[0]}</span> <span className={"text-" + textColor}>{titleSub.split(" ")[1]}</span></span>
            </h1>
            <p className="mx-auto mt-3 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              {firstParagraph}
              
            </p>
                        <p className="mx-auto mt-3 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              {secondParagraph}
              
            </p>

            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href={"#" + buttonAhref}
                  className={"flex w-full items-center justify-center rounded-md border border-transparent px-8 py-3 text-base font-medium text-white hover:bg-base-100 hover:text-white  md:py-4 md:px-10 md:text-lg bg-" + buttonMainColor}
                >
                  {buttonCTA} 
                </a>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <a
                  href="#contact"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-base-100 px-8 py-3 text-base font-medium text-white hover:bg-success md:py-4 md:px-10 md:text-lg"
                >
                  <span className="font-bold">{lowerButtonCta}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
          {heroImage}
        </div>
      </main>
    </div>
  )
}
