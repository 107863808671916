import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setLanguage } from '../../store/actions/langActions';
import logo from '../images/logo-small.png'
import logoalt from '../images/logo-small-violet.png'
import logolight from '../images/logo-small-lightviolet.png'
import { DevicePhoneMobileIcon  } from '@heroicons/react/24/outline';

const NavBarAlt = () => {
    const { language } = useSelector((state: RootState) => state.lang);
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownEl = useRef<HTMLUListElement>(null);
  // handle click outside of dropdown
  const handleClickOutside = useCallback(
    (e: any) => {
      if (dropdownEl.current && !dropdownEl.current.contains(e.target)) {
        setShowDropdown(false);
      }
    

    }, [showDropdown, setShowDropdown, dropdownEl]);

  // create an event listener for the handleClickOutside function
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [handleClickOutside]);

  const chooseLanguageHandler = (value: string) => {
    
    dispatch(setLanguage(value));
    setShowDropdown(false);

    
  }
  

    return (
<div className="navbar bg-gradient-to-r from-base-100 from-45%  to-neutral  to-100% ">
  <div className="flex-1">
    <a href="/" className="btn sm:px-0 justify-left text-xs btn-ghost normal-case font-bold text-primary  lg:text-3xl ">Jalapeño<span className="text-primary ml-1 ">S</span><span className="text-primary ">olutions</span><img src={logolight}/></a>
  </div>
  <div className="navbar-center  sm:ml-24">
    <ul className="bg-transparent rounded-box p-1 ">
      <li tabIndex={0}>
       <a href="#" className="block btn btn-ghost m-auto  py-5 text-primary font-bold hover:bg-gray-600" onClick={() => setShowDropdown(true)}>{language}</a>
      {showDropdown &&     
      <div className='absolute z-10 bg-neutral rounded-box p-2'> 
      <ul className=""   ref={dropdownEl}>
              
              <li  onClick={() => chooseLanguageHandler('NL')}> <a href="#nl" className="block btn btn-ghost px-4 py-2 text-primary  hover:bg-gray-400">NL</a></li>  
                <li onClick={() => chooseLanguageHandler('EN')}><a href="#en" className="block btn btn-ghost px-4 py-2 text-primary   hover:bg-gray-600">EN</a></li>  
                <li onClick={() => chooseLanguageHandler('DE')}><a href="#de" className="block btn btn-ghost px-4 py-2 text-primary   hover:bg-gray-600">DE</a></li>  
                <li onClick={() => chooseLanguageHandler('FR')}><a href="#fr" className="block btn btn-ghost px-4 py-2 text-primary   hover:bg-gray-600">FR</a></li>  
              </ul></div>
            } 
            

      </li>
    </ul>
  </div>
  <div className="navbar-end text-primary px-10"> <a className='text-primary font-bold ' href="tel:+31619307904"> <DevicePhoneMobileIcon className='h-6 text-primary' />  </a></div>
</div>
    )
} 

export default NavBarAlt