import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

interface Page {
  category: string; // new field
  title: string;
  headingOne: string;
  headingTwo: string;
  headingThree: string;
  headingFour: string;
}

const SeoPage: React.FC = () => {
  const [pages, setPages] = useState<Page[]>([]);

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    const response = await fetch('https://jpswebapp-container-app.greentree-216e271f.westeurope.azurecontainerapps.io/pages/');
    const data = await response.json();
    setPages(data);
  };

  return (
    <div>
      <h1>Pages</h1>
      <ul>
        {pages.map((page) => (
          <li key={page.title}>
            <Link to={`/${encodeURIComponent(page.category)}/${encodeURIComponent(page.title)}`}>{page.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SeoPage;
