import React, { Fragment } from 'react';
import FixedHero from '../components/heros/fixedhero';
import Features from '../components/features/featurecomp';
import Testimonial from '../components/testimonials/testimonial';
import CenterCta from '../components/cta/centercta';
import Footerdos from '../components/footer/footerdos';
import NavNoLang from '../components/nav/navnolang';
import nljps from '../nljps/home.json'
import Nljpsfeatures from '../nljps/nljpsfeatures';
import ContentTabSection from '../components/solcomponents/tabssecion';
import PricingCardCustom from '../components/pricing/pricesectioncustom';
import ContactFormMain from '../components/contact/contact-form-main';
import nltiers from '../locales/nl/nltiers.json';

const Solutions = () => {
  let colorPicker = true 
  const smalltitle = nltiers['smallTitle']
  const startCTA = nltiers['startCTA']
  const subCTA = nltiers['subCTA']
  const monthlyTiers = nltiers['monthlyTiers']
  const yearlyTiers = monthlyTiers.map(tier => ({ ...tier, priceMonthly: tier.priceYearly, priceYearly: tier.priceYearly * 10 }));
  return(

    <Fragment>
            <section className="intro">
        <div className="intro__overlay"></div>
        <div className="intro__body">

    < NavNoLang/>

        </div>
      </section>
      <section > 

               <FixedHero
        title= {nljps['home']}
        titleSub={nljps['about']}
        firstParagraph= {nljps['introText']}
        secondParagraph= {nljps['aboutUsText']} 
        buttonCTA= 'Oplossingen'
        buttonAhref='diensten'
        lowerButtonCta= 'Contact'
        mainColor = {colorPicker}

         />

      </section>
      <section>
        
                        <ContentTabSection
                        tagline= {nljps['tagline']}
        firstTabTitle={"Business Intelligence"}
        secondTabTitle={"Proces Optimalisatie"}
        thirdTabTitle={"Digital Marketing"}

  firstTabMainTitle = {nljps['firstTabMainTitle']}
  firstTabSubtitle = {nljps['firstTabSubtitle']}
  firstTabTitleOne  = {nljps['firstTabTitleOne']}
  firstTabContentOne  = {nljps['firstTabContentOne']}
  firstTabTitleTwo  = {nljps['firstTabTitleTwo']}
  firstTabContentTwo  = {nljps['firstTabContentTwo']}
  firstTabTitleThree  = {nljps['firstTabTitleThree']}
  firstTabContentThree  = {nljps['firstTabContentThree']}
  firstTabTitleFour  = {nljps['firstTabTitleFour']}
  firstTabContentFour  = {nljps['firstTabContentFour']}
  firstTabTitleFive  = {nljps['firstTabTitlteFive']}
  firstTabContentFive  = {nljps['firstTabContentFive']}
  firstTabCtaTitle= {nljps['genericTitle']}
  firstTabCtaSubtitle= {nljps['genericSubtitle']}
  firstTabCtaTwo =  {nljps['genericCTA']}
    firstTabCta  = {nljps['firstTabCta']}
    firstTabHref = {nljps['firstTabHref']}



        secondTabLiOnePara = {nljps['secondTabLiOnePara']}
    secondTabLiTwoPara = {nljps['secondTabLiTwoPara']}
    secondTabLiThreePara = {nljps['secondTabLiThreePara']}
    secondTabLiFourPara = {nljps['secondTabLiFourPara']}

  
        tabThreeMainTitle={nljps['tabThreeMainTitle']}
        tabThreeSubtitle={nljps['tabThreeSubtitle']}
        tabThreeTitleOne={nljps['tabThreeTitleOne']}
        tabThreeContentOne={nljps['tabThreeContentOne']}
        tabThreeTitleTwo={nljps['tabThreeTitleTwo']}
        tabThreeContentTwo={nljps['tabThreeContentTwo']}
        tabThreeTitleThree={nljps['tabThreeTitleThree']}
        tabThreeContentThree={nljps['tabThreeContentThree']}
        tabThreeTitleFour={nljps['tabThreeTitleFour']}
        tabThreeContentFour={nljps['tabThreeContentFour']}
        tabThreeTitleFive={nljps['tabThreeTitleFive']}
        tabThreeContentFive={nljps['tabThreeContentFive']}

        tabThreeCta={nljps['tabThreeCta']}

        secondTabMainTitle={nljps['secondTabMainTitle']}
        secondTabSubtitle={nljps['secondTabSubtitle']}
        secondTabPone= {nljps['secondTabPone']}
        secondTabPtwo= {nljps['secondTabPtwo']}
        secondTabPthree= {nljps['secondTabPthree']}
        secondTabPfour= {nljps['secondTabPfour']}
        secondTabLiFivePara= {nljps['secondTabLiFivePara']}
        secondTabLiOne= {nljps['secondTabLiOne']}
        secondTabLiTwo= {nljps['secondTabLiTwo']}
        secondTabLiThree= {nljps['secondTabLiThree']}
        secondTabLiFour= {nljps['secondTabLiFour']}
          secondTabLiFive= {nljps['secondTabLiFive']}

        secondTabCtaTitle= {nljps['secondTabCTA']}
        secondTabHref = {nljps['secondTabHref']}

        specialColor={colorPicker}

                  />
        </section>

        <section>

        <Features title= {nljps['FeatureSectionTitle']}
        subtitle=  {nljps['FeatureSectionSubtitle']}
        bigtitle= {nljps['FeatureSectionBigtitle']}
        fdata = {Nljpsfeatures['features']}
        specialColor = {colorPicker}
        />
                    <CenterCta
          ctaTitle= "Samenwerken?"
          ctaShortsub= {nljps['genericTitle']}
          ctaLongsub= {nljps['genericSubtitle']}
          ctaButton= {nljps['genericCTA']}
        />
                <Testimonial />
      <section id="pricing"> 
        <PricingCardCustom 
      smallTitle={smalltitle}
      startCTA={startCTA}
      subCTA={subCTA}
      monthlyTiers={monthlyTiers}
      yearlyTiers={yearlyTiers}
      

        
        
        />
        </section>
        <ContactFormMain
        contactCta = {nljps['contactCta']}
        FirstName = {nljps['FirstName']}
        LastName = {nljps['LastName']}
        company = {nljps['Company']}
        email = {nljps['Email']}
        phonenumber = {nljps['Phone']}
        interest = {nljps['interest']}
        message = {nljps['message']}
        privacy = {nljps['privacy']}
        interests= {nljps['interests']}
        thankYouMessage = {nljps['thankYouMessage']}
        thankYouSubMessage = {nljps['thankYouSubMessage']}

        />

        </section>
        <Footerdos/>
       </Fragment>
  );
}

export default Solutions;
