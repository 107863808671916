import Feature from './feature';

type featureProps = {
    title: string;
    subtitle: string;
    bigtitle: string;
    fdata : any;
    specialColor : Boolean;
}

const Features = ({title, subtitle, bigtitle, fdata, specialColor} : featureProps) => {
    return (
                    <Feature title={title} 
                    titleSub={subtitle}
                    bigtitle={bigtitle}
                    fdata = {fdata}
                    specialColor={specialColor}
                    />

    );
} 

export default Features