import {ReactComponent as Bargraph} from '../svgs/bargraph.svg'
import {ReactComponent as Arrowchart} from '../svgs/arrowchart.svg'
import {ReactComponent as LaptopPhone} from '../svgs/laptopphone.svg'
import {ReactComponent as CloudOpslag} from '../svgs/cloudicon.svg'
import {ReactComponent as SalesIcon} from '../svgs/sales.svg'
import {ReactComponent as AlarmIcon} from '../svgs/alarmcheck.svg'

const frFeatures = 

{ 
    "features" : [{ 

        'title' : 'Obtenez des informations précieuses',
        'subtitle' : "Obtenez une vue d'ensemble de votre activité",
        'svg' : <Bargraph/>    
    },

    {

    'title' : 'Économiser du temps',
    'subtitle' : 'Se concentrer sur ce qui compte',
    'svg' : <AlarmIcon/>
    
    },
{
    'title' : 'Amélioration de la prise de décision',
    'subtitle' : 'Sur la base de données',
    'svg' : <Arrowchart/>
    
    }, {
    'title' : 'Tout appareil',
    'subtitle' : 'Bureau, mobile, tablette. Partout',
    'svg' : <LaptopPhone/>
    
    }, {
    'title' : 'Accès 24/7',
    'subtitle' : 'Accédez à vos données partout, à tout moment',
    'svg' : <CloudOpslag/>
    
    }, {
    'title' : 'Obtenir des résultats',
    'subtitle' : 'Laissez les données travailler pour vous',
    'svg' : <SalesIcon/>
    
    }

    
    ],

    

}
export default frFeatures