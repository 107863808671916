/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from 'react'
import { Switch } from '@headlessui/react'
import { useLocation } from 'react-router-dom';
import ga4 from 'react-ga4';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function ContactFormAlt(props) {
  
  const [agreed, setAgreed] = useState(false)
  const [formData, setFormData] = useState({ firstname: "", lastname: "", company: "", email: "", phonenumber: "", interest: "Business-intelligence", message: "Message" });
  const [isSubmitted, setIsSubmitted] = useState(false);
  

  const handleSubmit = async (event) => {
  console.log(formData)
  event.preventDefault();
  const response = await fetch("https://jpswebapp-container-app.greentree-216e271f.westeurope.azurecontainerapps.io/submit-form/", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData),
  });
  if (response.ok) {
    setIsSubmitted(true);
    ga4.event({
  action: 'submit_form',
  params: {
    event_category: 'Contact Form',
    event_label: 'Form Submission',
  },
});
  }
};
    const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="overflow-hidden bg-gradient-to-r from-base-100 to-neutral to-75% py-16 px-4 sm:px-6 lg:px-8 lg:py-24" id="contact">
      <div className="relative mx-auto max-w-xl">
          {isSubmitted ? (
        <div className='text-center mt-0'> 
          <h2 className="text-3xl font-bold tracking-tight text-primary sm:text-4xl">Bedankt voor je bericht</h2>
          <p className="mt-4 text-lg leading-6 text-primary">
            We nemen zo snel mogelijk contact met je op.
          </p>
        </div>
          ) : (
        <><div className="text-center">
                <h2 className="text-3xl font-bold tracking-tight text-primary sm:text-4xl">Contact sales</h2>
                <p className="mt-4 text-lg leading-6 text-primary">
                  {props.contactCta}
                </p>
              </div><div className="mt-12">
                  <div>
                    {isSubmitted ? (
                      <div className='popup'>
                        <p>thanks for reaching out</p>
                      </div>

                    ) : (
                      <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                        <div>
                          <label className="block text-sm font-medium text-primary">
                            {props.FirstName}
                          </label>
                          <div className="mt-1 text-neutral">
                            <input
                              type="text"
                              name="firstname"
                              id="name"
                              autoComplete="given-name"
                              value={formData.firstname}
                              onChange={handleChange}
                              className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-primary">
                           {props.LastName}
                          </label>
                          <div className="mt-1 text-neutral">
                            <input
                              type="text"
                              name="lastname"
                              id="lastname"
                              value={formData.lastname}
                              onChange={handleChange}

                              autoComplete="family-name"
                              className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label className="block text-sm font-medium text-primary">
                            {props.company}
                          </label>
                          <div className="mt-1 text-neutral">
                            <input
                              type="text"
                              name="company"
                              value={formData.company}
                              onChange={handleChange}

                              id="company"
                              autoComplete="organization"
                              className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label className="block text-sm font-medium text-primary">
                            {props.email}
                          </label>
                          <div className="mt-1 text-neutral">
                            <input
                              value={formData.email}
                              onChange={handleChange}

                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
                          </div>
                        </div>
                        <div className="sm:col-span-2 text-primary">
                          {props.phonenumber}
                          <label className="block text-sm font-medium text-primary">
                            Phone Number
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <div className="absolute text-neutral inset-y-0 left-0 flex items-center">
                              <input
                                value={formData.phonenumber}
                                onChange={handleChange}

                                type="text"
                                name="phonenumber"
                                id="phone-number"
                                autoComplete="tel"
                                className="block w-full rounded-md border-gray-300 py-3 px-4 pl-20 focus:border-indigo-500 focus:ring-indigo-500"
                                placeholder="+31 612345678" />
                            </div>
                          </div>
                        </div>
                        <div className="sm:col-span-2 lg:pt-2">
                          <label className="block text-sm lg:text-lg font-medium text-primary">
                            {props.interest} 
                          </label>
                          <div className="relative my-1 py-2 rounded-md shadow-sm">
                            <div className="absolute left-0 flex items-center">
                              <label className="sr-only">
                                Interesse
                              </label>
<select
    id="interest"
    name="interest"
    value={formData.interest}
    onChange={handleChange}
    className="h-full rounded-md border-transparent bg-white text-neutral py-0 pl-4 pr-8  focus:border-neutral focus:ring-neutral"
>
    {props.interests.map((interest) => (
        <option key={interest} value={interest}>{interest}</option>
    ))}
</select>
                            </div>
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label htmlFor="message" className="block text-sm font-medium text-primary">
                            {props.message}
                          </label>
                          <div className="mt-1 text-neutral">
                            <textarea
                              id="message"
                              name="message"
                              value={formData.message}
                              onChange={handleChange}

                              rows={4}
                              className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                               />
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <div className="flex items-start">
                            <div className="flex-shrink-0">
                              <Switch
                                checked={agreed}
                                onChange={setAgreed}
                                className={classNames(
                                  agreed ? 'bg-neutral' : 'bg-gray-200',
                                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                )}
                              >
                                <span className="sr-only">Agree to policies</span>
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    agreed ? 'translate-x-5' : 'translate-x-0',
                                    'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                  )} />
                              </Switch>
                            </div>
                            <div className="ml-3">
                              <p className=" text-primary">
                                <a href="/privacy-policy" className="font-medium text-primary underline">
                                  {props.privacy}
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <button
                            type="submit"
                            className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-neutral px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Let's talk
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div></>
          // here 
          ) } 
        </div>

      </div>
  ) 
}
