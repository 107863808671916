/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/
import LogoJM from "../logos/logonew"
import React, { useState } from 'react';



type contentProps = {
    secondTabMainTitle: string;
    secondTabSubtitle: string;
    secondTabPone: string;
    secondTabPtwo: string;
    secondTabPthree: string;
    secondTabLiOne: string;
    secondTabLiTwo: string;
    secondTabLiThree: string;
    secondTabLiFour: string;
    secondTabPfour : string;
    secondTabCtaTitle : string;
    secondTabHref : string;
    secondTabLiOnePara: string;
    secondTabLiTwoPara: string;
    secondTabLiThreePara: string;
    secondTabLiFourPara: string;
    secondTabLiFivePara: string;
    secondTabLiFive: string; 

}

export default function SecondTabContent( {secondTabMainTitle, secondTabSubtitle, secondTabPone, secondTabPtwo, secondTabPthree, secondTabLiOne, secondTabLiTwo, secondTabLiThree, secondTabLiFour,secondTabLiFive, secondTabLiFivePara, secondTabPfour, secondTabCtaTitle, secondTabHref, secondTabLiOnePara, secondTabLiTwoPara, secondTabLiThreePara, secondTabLiFourPara} : contentProps) {
  const [showPara, setShowPara] = useState({one: false, two: false, three: false, four: false, five: false});

  const handleClick = (item: string) => {
    if (showPara[item as keyof typeof showPara]) {
      setShowPara(prevState => ({ ...prevState, [item]: false }));
    } else {
      setShowPara({one: false, two: false, three: false, four: false, five:false, [item]: true});
    }
  }
  return (
    <div className="overflow-hidden bg-gray-50  py-16 px-4 sm:px-6 lg:px-8 xl:py-36">
      <div className="mx-auto max-w-max lg:max-w-7xl">
        <div className="relative z-10 mb-8 md:mb-2 md:px-6">
          <div className="max-w-prose text-base lg:max-w-none">
            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-neutral sm:text-4xl">
              {secondTabMainTitle}
            </p>
             <p className="mt-2 text-4xl font-bold leading-8 tracking-tight text-neutral sm:text-4xl">
              {secondTabSubtitle}
              </p>
          </div>
        </div>
        <div className="relative">
          <svg
            className="absolute top-0 right-0 -mt-20 -mr-20 hidden md:block md:[overflow-anchor:none]"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
          </svg>
          <svg
            className="absolute bottom-0 left-0 -mb-20 -ml-20 hidden md:block md:[overflow-anchor:none]"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
          </svg>
          <div className="relative md:bg-white md:p-6">
            <div className="lg:grid lg:grid-cols-2 lg:gap-6">
              <div className="prose text-left prose-lg prose-indigo text-neutral lg:max-w-none">
                <p>
                  {secondTabPone}
                </p>
                <p>
                  {secondTabPtwo}
                  </p>
                <p>
                  {secondTabPthree}
                </p>
              </div>
              
              <div className="prose text-left prose-lg prose-indigo mt-6 text-neutral lg:mt-0">
                <ul>
                  <li className="underline select-none text-neutral cursor-pointer" onClick={() => handleClick('one')}>{secondTabLiOne}</li>
                  {showPara.one && <p className="select-none">{secondTabLiOnePara}</p>}
                  <li className="underline select-none text-neutral cursor-pointer" onClick={() => handleClick('two')}>{secondTabLiTwo}</li>
                  {showPara.two && <p className="select-none">{secondTabLiTwoPara}</p>}
                  <li className="underline select-none text-neutral cursor-pointer" onClick={() => handleClick('three')}>{secondTabLiThree}</li>
                  {showPara.three && <p className="select-none">{secondTabLiThreePara}</p>}
                  <li className="underline select-none text-neutral cursor-pointer" onClick={() => handleClick('four')}>{secondTabLiFour}</li>
                  {showPara.four && <p className="select-none">{secondTabLiFourPara}</p>}
                  <li className="underline select-none text-neutral cursor-pointer" onClick={() => handleClick('five')}>{secondTabLiFive}</li>
                  {showPara.five && <p className="select-none">{secondTabLiFivePara}</p>}

                </ul>
                <h3 className="text-neutral lg:text-3xl">Samenwerken?</h3>
                <p>{secondTabPfour}</p>
              </div>
              
            </div>
            <LogoJM />
                            <div className="mt-0  rounded-md shadow">
              <a
                href={secondTabHref}
                target="_blank"
                className="flex no-underline items-center justify-center rounded-md border border-transparent bg-neutral px-5 py-3 text-base font-medium text-white hover:bg-indigo-700"
              >
                {secondTabCtaTitle}
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
