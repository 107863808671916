import {ReactComponent as Bargraph} from '../locales/svgs/bargraph.svg'
import {ReactComponent as Arrowchart} from '../locales/svgs/arrowchart.svg'
import {ReactComponent as SalesIcon} from '../locales/svgs/sales.svg'
import {ReactComponent as AlarmIcon} from '../locales/svgs/alarmcheck.svg'
import {ReactComponent as DashboardIcon} from '../locales/svgs/dashboard.svg'
import {ReactComponent as OplossingIcon} from '../locales/svgs/maatoplossing.svg'

const Nljpsfeatures = 

{ 
    "features" : [{ 

        'title' : 'Vooruitstrevende Inzichten',
        'subtitle' : 'Ontdek de waarde in uw gegevens.',
        'svg' : <Bargraph/>    
    },

    {

    'title' : 'Bespaar Tijd',
    'subtitle' : 'Focus op wat belangrijk is.',
    'svg' : <AlarmIcon/>
    
    },
{
    'title' : 'Data-driven Marketing',
    'subtitle' : 'Data-gedreven beslissingen, betere resultaten.',
    'svg' : <DashboardIcon/>
    
    }, {
    'title' : 'Betere besluitvorming',
    'subtitle' : 'Data-gedreven beslissingen, betere resultaten.',
    'svg' : <Arrowchart/>
    
    }, {
    'title' : 'Oplossingen op Maat',
    'subtitle' : 'Specifiek & Schaalbaar.',
    'svg' : <OplossingIcon/>
    
    }, {
    'title' : 'Boek resultaat',
    'subtitle' : 'Laat data in uw voordeel werken.',
    'svg' : <SalesIcon/>
    
    }

    
    ],

    

}
export default Nljpsfeatures