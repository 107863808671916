import React, { FC, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/Homepage';
import SeoPage from './pages/Seopage';
import PageDetail from './pages/Seopagedetail'
import PostPage from './pages/Postpage';
// import ga4 from 'react-ga4';
import TagManager from 'react-gtm-module';
import Solutions from './pages/Jps';
import CookieNotification from './components/cookies/cookiepopup';
import PrivacyPage from './pages/Privacy';
import Partners from './pages/Partners';

const App: FC = () => {
  const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID; // Replace with your GA4 Tracking ID
  const GTM_ID = process.env.REACT_APP_GTM_ID; // Replace with your GTM ID
  
useEffect(() => {
    if(GA_TRACKING_ID && GTM_ID){
        // Initialize Google Analytics
       //ga4.initialize(GA_TRACKING_ID);

        // Initialize Google Tag Manager
        TagManager.initialize({ gtmId: GTM_ID });
        

        // Optionally, you can enable page tracking with GA4       /*   <Route path="/pageposter" element={<PostPage/>} /> */            <Route path="/:category/:pageTitle" element={<PageDetail/>} />

    }
  }, [GA_TRACKING_ID, GTM_ID]); 

  return (
    <>
      <BrowserRouter>
        <Routes>           
          <Route path="" element={<Solutions/>} />
   <Route path="/business-intelligence" element={<HomePage/>} >

  <Route path=":lang" element={<HomePage/>} /> 
  <Route path=":lang" element={<HomePage/>}  />           
  <Route path=":lang" element={<HomePage/>}  />   
  <Route path=":lang" element={<HomePage/>}  />
  </Route>
          <Route path="/partners" element={<Partners />} />
          <Route path="/pages" element={<SeoPage/>} />
          <Route path="/pages/:pageTitle" element={<PageDetail/>} />
  
         <Route path="*" element={<Navigate to="" />} />
<Route path="/privacy-policy" element={<PrivacyPage />} />
        </Routes>
      </BrowserRouter>

            <CookieNotification />
    </>
  );
}

export default App;
