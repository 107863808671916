type ctaProps = {
     ctaTitle: string;
     ctaShortsub: string;
     ctaLongsub: string;
     ctaButton: string;
}



const CenterCta = ({ctaTitle, ctaShortsub, ctaLongsub, ctaButton} : ctaProps ) => { 

    return (
    <div className="bg-neutral">
      <div className="mx-auto max-w-2xl py-8 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
        <h2 className=" text-5xl font-bold tracking-tight text-primary sm:text-4xl">
          <span className="block">{ctaTitle}</span>
          <span className="block">{ctaShortsub}</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-primary">
          {ctaLongsub}
        </p>
        <a
          href="#contact"
          className="mt-8 inline-flex w-full items-center justify-center rounded-md border-transparent bg-primary px-8 py-1 text-neutral font-bold hover:bg-indigo-700 hover:text-primary md:py-4 md:px-10 md:text-lg"
        >
          {ctaButton}
        </a>
      </div>
    </div>



    )
}

export default CenterCta