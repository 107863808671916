import { StringLiteral } from 'typescript';
import logolight from '../images/logo-small-lightviolet.png'
import { DevicePhoneMobileIcon  } from '@heroicons/react/24/outline';

const NavNoLang = () => {
  

    return (
<div className="navbar bg-gradient-to-r from-base-100 from-45%  to-neutral to-100% ">
  <div className="flex-1">
    <a href="/" className="btn sm:px-0 justify-left text-xs btn-ghost normal-case font-bold text-primary lg:text-3xl ">Jalapeño <span className="text-primary ml-1 ">S</span><span className="text-primary ">olutions</span><img src={logolight}/></a>
  </div>
  <div className="navbar-center sm:ml-24 flex items-center">
    
    <a className='text-primary font-bold ' href="tel:+31619307904"><DevicePhoneMobileIcon className='h-6 text-primary' /></a>
    <a className='text-primary font-bold ' href="tel:+31619307904">Bel Ons</a>
  </div>
  <div className="navbar-end text-primary rounded-box px-10"> Login </div>
</div>
    )
} 

export default NavNoLang