type headerProps = { 

    title: string; 
    titledos: string;
    subtitle: string;
    charColor: Boolean;
}

const HeaderSection = ({ title, titledos, subtitle, charColor} : headerProps) => {

  let specialColor = "neutral"
  let subtitlesplit = subtitle.split(" ")
  if (charColor != true) {
    specialColor = "warning"
  }
    return (
        <div className=""> 
                <div className="">
      <div className="mx-auto max-w-7xl">
        <div className="text-center ">
          <h2 className="mt-1 text-4xl font-bold tracking-tight text-primary sm:text-5xl lg:text-6xl">
           <span className="">{title} {titledos}</span>
          </h2>
          <h3 className="mx-auto mt-5 max-w-xl text-xl font-bold italic text-primary" >
            {subtitle}
          </h3>
        </div>
      </div>
    </div>

        </div>
    
    ); 
}


export default HeaderSection