import React, { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';

type PriceFeature = string;

type PriceOption = {
  name: string;
  id: string;
  priceMonthly: number;
  priceYearly: number;
  description: string;
  features: PriceFeature[];
  href: string;
  featured: boolean;
  cta: string;
};

type FrequencyOption = {
  value: string;
  label: string;
  priceSuffix: string;
};

type CardProps = {
  smallTitle: string;
  startCTA: string;
  subCTA: string;
  monthlyTiers: PriceOption[];
  yearlyTiers: PriceOption[];
  frequencies: FrequencyOption[];
};

function classNames(...classes: (false | null | undefined | string)[]) {
  return classes.filter(Boolean).join(' ');
}

const PricingCardMultilang: React.FC<CardProps> = ({
  smallTitle,
  startCTA,
  subCTA,
  monthlyTiers,
  yearlyTiers,
  frequencies,
}) => {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const tiers = frequency.value === 'monthly' ? monthlyTiers : yearlyTiers;
  const [selectedTier, setSelectedTier] = useState('');
  return (
    <div className="bg-gradient-to-r from-base-100 to-neutral to-75% pb-24 pt-16 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h3 className="mt-2 text-7xl font-bold tracking-tight text-primary sm:text-5xl">
            {smallTitle}
          </h3>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-primary">
         {startCTA}
        </p>
        <div className="mt-8 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset bg-primary ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-neutral text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full px-2.5 py-1'
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.featured ? 'bg-gray-900 ring-gray-900' :  ' bg-primary ring-neutral',
                'rounded-3xl p-8 ring-1 xl:p-10'
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.featured ? 'text-white' : 'text-gray-900',
                  'text-lg font-semibold leading-8'
                )}
              >
                {tier.name}
              </h3>
              <p className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-4 text-sm leading-6')}>
                {tier.description}
              </p>
<p className="mt-6 flex items-baseline gap-x-1">
  <span
    className={classNames(
      tier.featured ? 'text-white' : 'text-gray-900',
      'text-4xl font-bold tracking-tight'
    )}
  >
    {frequency.value === 'monthly' ? "€" + tier.priceMonthly : "€" + tier.priceYearly}
  </span>
  <span
    className={classNames(
      tier.featured ? 'text-gray-300' : 'text-gray-600',
      'text-sm font-semibold leading-6'
    )}
  >
    {frequency.priceSuffix}
  </span>
</p>



              <a 
                onClick={() => setSelectedTier(tier.name)}
                href= {tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.featured
                    ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white'
                    : 'bg-neutral text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600',
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                )}
              >
                {tier.cta}
              </a>
              <ul
                role="list"
                className={classNames(
                  tier.featured ? 'text-gray-300' : 'text-gray-600',
                  'mt-8 space-y-3 text-sm leading-6 xl:mt-10'
                )}
              >
               {tier.features.map((feature) => (
  <li key={feature} className="flex gap-x-3">
    <CheckIcon
      className={classNames(tier.featured ? 'text-white' : 'text-indigo-600', 'h-6 w-5 flex-none')}
      aria-hidden="true"
    />
    {feature}
  </li>
))}

              </ul>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default PricingCardMultilang;
