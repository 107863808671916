import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NavNoLang from '../components/nav/navnolang';
import Footerdos from '../components/footer/footerdos';
import { Helmet } from 'react-helmet-async';

interface Page {
  title: string; // H1 tag, meta title, and URL slug
  firsth2: string;
  firsth2content: string;
  secondh2: string;
  secondh2content: string;
  thirdh2: string;
  thirdh2content: string;
  h3: string;
  h3content: string;
  category: string;
}


const PageDetail: React.FC = () => {
  const [page, setPage] = useState<Page | null>(null);
const { pageTitle, category } = useParams() as { pageTitle: string, category: string };

  useEffect(() => {
    fetchPage();
  }, [pageTitle, category]);

const fetchPage = async () => {
  const response = await fetch(`https://jpswebapp-container-app.greentree-216e271f.westeurope.azurecontainerapps.io/pages/${encodeURIComponent(pageTitle)}`);
  const data = await response.json();
  setPage(data);
};

  return (
    <div>

        <NavNoLang /> 
      {page ? (
        
        <div className='bg-primary px-6 py-16 lg:px-8'> 
                <Helmet>
          <title>{page.title}</title>
          <meta name="description" content={page.title} />
          {/* Add more meta tags as needed */}
        </Helmet>

        <div className="mx-auto max-w-3xl text-base leading-7 text-error">
          <h2 className='mt-2 text-4xl font-bold tracking-tight text-error sm:text-4xl'>{page.firsth2}</h2>
          <div className="mt-10 max-w-2xl">
                      <p>
            {page.firsth2content}
          </p>
            </div> 
            
          <h2 className='mt-2 text-4xl font-bold tracking-tight text-error sm:text-4xl'>{page.secondh2}</h2>
                    <div className="mt-10  max-w-2xl">
                      <p>
             {page.secondh2content}
          </p>

            </div> 

          <h2 className='mt-2 text-4xl font-bold tracking-tight text-error sm:text-4xl'>{page.thirdh2}</h2>
                    <div className="mt-10 max-w-2xl">
                      <p>
            {page.thirdh2content}
          </p>

            </div> 

          <h3 className='mt-4 text-2xl font-bold tracking-tight text-error sm:text-2xl'>{page.h3}</h3>
         <div className="mt-10 max-w-2xl">
          <p>{page.h3content}

          </p>
          </div>
          </div>
          </div>
      ) : (
        <p>Loading...</p>
      )}
                  <Footerdos/>

    </div>
    
  );
};

export default PageDetail;
