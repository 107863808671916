import HeaderSectionDark from "../headers/headersectiondark";
type featureProps = {

    title: string;
    titleSub: string;
    bigtitle?: string;
    fdata : any[];
    specialColor : Boolean;
}

const Feature = ({title, titleSub, bigtitle, fdata, specialColor} : featureProps) => {

    let featureColor = "neutral"

    if (specialColor != true) {
      featureColor = "neutral"
    }
    return (
    <div className={"relative bg-gray-50 py-14 sm:pb-24 lg:pb-32 text-"+featureColor} id="usp">
            <HeaderSectionDark
    title={""}
    subtitle={""}
    bigtitle={""}
    />

      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8  sm:grid-cols-1 lg:grid-cols-3">
            {fdata?.map((feature) => (
              <div key={feature.title} className="pt-6">
                <div className={"flow-root rounded-lg px-6 pb-8 bg-" + featureColor}>
                  <div className="-mt-6">
                    <div>
                      <span className={"inline-flex items-center justify-center rounded-md p-3 shadow-lg bg-" + featureColor}>
                        {feature.svg}
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-primary">{feature.title}</h3>
                    <p className="mt-5 text-base text-primary">{feature.subtitle}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feature
