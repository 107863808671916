import {ReactComponent as Bargraph} from '../svgs/bargraph.svg'
import {ReactComponent as Arrowchart} from '../svgs/arrowchart.svg'
import {ReactComponent as LaptopPhone} from '../svgs/laptopphone.svg'
import {ReactComponent as CloudOpslag} from '../svgs/cloudicon.svg'
import {ReactComponent as SalesIcon} from '../svgs/sales.svg'
import {ReactComponent as AlarmIcon} from '../svgs/alarmcheck.svg'

const nlFeatures = 

{ 
    "features" : [{ 

        'title' : '24/7 Inzage',
        'subtitle' : 'Altijd en overal toegang tot uw data.',
        'svg' : <Bargraph/>    
    },

    {

    'title' : 'Bespaar Tijd',
    'subtitle' : 'Focus op wat belangrijk is.',
    'svg' : <AlarmIcon/>
    
    },
{
    'title' : 'Betere besluitvorming',
    'subtitle' : 'Op basis van data.',
    'svg' : <Arrowchart/>
    
    }, {
    'title' : 'Elk apparaat',
    'subtitle' : 'Desktop, mobiel, tablet. Altijd & Overal.',
    'svg' : <LaptopPhone/>
    
    }, {
    'title' : 'Altijd beschikbaar',
    'subtitle' : 'Altijd en overal toegang tot uw data.',
    'svg' : <CloudOpslag/>
    
    }, {
    'title' : 'Boek resultaat',
    'subtitle' : 'Laat data in uw voordeel werken.',
    'svg' : <SalesIcon/>
    
    }

    
    ],

    

}
export default nlFeatures