import PrivacyComponent from "../components/privacy/privacypolicy";

const privacyContentData = [
  {
    title: 'Privacy Verklaring',
    content: 'In dit document staat vastgelegd hoe Jalapeno Solutions omgaat met het verwerken van uw persoonsverwerking. Jalapeno Solutions is onderdeel van Jalapeno Marketing. Jalapeno Marketing is ingeschreven bij de Kamer Van Koophandel onder het nummer 74487434 en is gevestigd aan Lage der A 3-F te Groningen. ',
  },
  {
    title: 'Doeleinden van de gegevensverwerking',
    content: 'Doeleinden van de gegevensverwerking betreffen het verbeteren van onze dienstverlening, het verzenden van onze nieuwsbrief, goederen of diensten af te leveren, bellen of e-mailen indien noodzakelijk voor onze dienstverlening, informeren over goederen en/of diensten en bezoekersanalyse op de website met als doel de website en het aanbod beter af te stemmen op de voorkeuren van de bezoeker. ',
  },
   {
    title: 'Grondslag voor de verwerking',
    content: 'Het verwerken van persoonsgegevens is gebaseerd op de volgende grondslagen: (i) wettelijke verplichting, (ii) uitvoering van een overeenkomst, (iii) verkregen (uitdrukkelijke) toestemming van betrokkenen, en (iv) gerechtvaardigd belang. Als onderdeel van de dienstverlening van Jalapeno Solutions worden persoonsgegevens verwerkt. Jalapeno Solutions verwerkt alleen de gegevens die Jalapeno Solutions noodzakelijk acht voor het leveren en verbeteren van de diensten, en behandelt zorgvuldig de verzamelde (persoons)gegevens met betrekking tot uw gebruik van de diensten. De verwerking van deze gegevens is gebaseerd op de overeenkomst die u met Jalapeno Solutions heeft gesloten. Bovendien kunnen er persoonsgegevens van u worden verwerkt wanneer u toestemming heeft gegeven door de website www.jalapenosolutions.nl te bezoeken.',
   },

    {
    title: 'Noodzaak van de verwerking',
    content: 'Voor Jalapeno Solutions is de verwerking van uw persoonsgegevens dermate noodzakelijk om de dienstverlening uit te kunnen voeren. De aangeboden diensten zouden zonder het verwerken van uw persoonsgegevens niet compleet uitgevoerd kunnen worden.'
    },

    {
    title: 'Bewaartermijn',
    content: 'Jalapeno Solutions houdt zich aan de geldende wet- en regelgeving bij het bewaren van persoonsgegevens. Als er een wettelijke verplichting is om persoonsgegevens langer te bewaren, zorgen we ervoor dat dit wordt nageleefd. We bewaren alle ontvangen persoonsgegevens niet langer dan absoluut noodzakelijk.'

    },
{
    title: 'Verwerking door derden',
    content: 'Jalapeno Solutions deelt alleen persoonsgegevens met derden als het absoluut noodzakelijk is voor het vervullen van een overeenkomst en om te voldoen aan toepasselijke wet- en regelgeving. We verkopen geen persoonsgegevens. Er kan een wettelijke verplichting rusten op Jalapeno Solutions om persoonsgegevens met derden te delen. Wanneer persoonsgegevens met derden worden gedeeld, worden er verwerkers overeenkomsten afgesloten. De volgende partijen zijn ontvangers van persoonsgegevens: De Boekhouder, ten behoeve van contractuitvoering. De verwerkte persoonsgegevens omvatten contact- en NAW-gegevens, evenals financiële gegevens. Softwareleveranciers, ten behoeve van contractuitvoering. De verwerkte persoonsgegevens omvatten locatiegegevens, e-mailadressen en contact- en NAW-gegevens.'
    },

    {
        title: 'Beveiliging van Persoonsgegevens',
        content: "Bij Jalapeno Solutions hechten we veel waarde aan de bescherming van uw persoonsgegevens. We nemen dit uiterst serieus en nemen passende technische en organisatorische maatregelen, rekening houdend met de laatste stand van de techniek, de uitvoeringskosten en de aard, omvang, context en verwerkingsdoelen van de gegevens. We streven ernaar een beveiligingsniveau te waarborgen dat in verhouding staat tot de risico's die mogelijk van invloed zijn op de rechten en vrijheden van individuen, met betrekking tot de waarschijnlijkheid en ernst van die risico's."
    },

    {        title: 'Cookies en vergelijkbare technologieën',
        content: "We verzamelen verschillende soorten cookies in onze systemen om meer inzicht te krijgen in het bezoekersgedrag op de website. Dit gaat om functionele cookies, analytische cookies en tracking cookies. Analytische cookies gebruiken we om in te zien hoe vaak onze website wordt bezocht en bijvoorbeeld welke pagina’s het beste voor ons werken.Tracking cookies zijn de ‘advertising cookies’, die we kunnen gebruiken om jouw surfgedrag op onze website te volgen, zodat we je gepersonaliseerde online advertenties kunnen aanbieden. Wij maken specifiek gebruik van trackingcookies door Google (Doubleclick en AdWords), Leadinfo en Facebook. Wij vragen enkel toestemming voor gebruik van tracking cookies, de functionele en analytische cookies worden automatisch geplaatst en hebben geen impact op jouw privacy. Je kunt je toestemming voor het gebruik van tracking cookies altijd intrekken door je cookievoorkeuren aan te passen." 


    },
{
     title: "Meer weten?",
 
     content: "Wil je meer weten over het cookiebeleid van Jalapeno Marketing? Of heb je vragen over de Algemene verordening gegevensbescherming (AVG of GDPR in het Engels) voor jouw bedrijf? Mail dan gerust naar info@jalapenomarketing.nl."
}
];


const PrivacyPage: React.FC = () => {
    return <PrivacyComponent privacyTitle="Privacy Verklaring Jalapeno Solutions" privacyContent={privacyContentData} />; };

export default PrivacyPage;