// we want to create 4 different divs that will be used to display the content of the tabs
import { useState } from "react";
import Contentexampledos from "./contentexampledos";
import Contentexampletres from "./contentexampletres";
import HeaderSection from "../headers/headersection";
import Contentcuatro from "./contentcuatro";


type tabs = {
  tagline: string; 
  firstTabTitle: string;
  secondTabTitle: string;
  thirdTabTitle: string;
    managementMainTitle: string;
  managementSubtitle: string;
  managementTitleOne : string;
  managementContentOne : string;
  managementTitleTwo : string;
  managementContentTwo : string;
  managementTitleThree : string;
  managementContentThree : string;
  managementTitleFour : string;
  managementContentFour : string;
  managementTitleFive : string;
  managementContentFive : string;
    managementCtaTitle : string;
  managementCtaSubtitle : string;
  managementCta : string;

  
    stakeholderMainTitle: string;
  stakeholderSubtitle: string;
  stakeholderTitleOne : string;
  stakeholderContentOne : string;
  stakeholderTitleTwo : string;
  stakeholderContentTwo : string;
  stakeholderTitleThree : string;
  stakeholderContentThree : string;
  stakeholderCta : string;

      marketingMainTitle: string;
  marketingSubtitle: string;
  marketingPone: string;
  marketingPtwo: string;
  marketingPthree: string;
  marketingLiOne: string;
  marketingLiTwo: string;
  marketingLiThree: string;
  marketingLiFour: string;
  marketingPfour : string;
  marketingCtaTitle : string;
  specialColor:  Boolean;


  
};

const Contenttabs = ({tagline, firstTabTitle, secondTabTitle, thirdTabTitle, managementMainTitle, managementSubtitle, managementTitleOne, managementContentOne, managementTitleTwo, managementContentTwo, managementTitleThree, managementContentThree, managementTitleFour, managementContentFour, managementTitleFive, managementContentFive, managementCtaTitle, managementCtaSubtitle, managementCta,  stakeholderMainTitle, stakeholderSubtitle, stakeholderTitleOne, stakeholderContentOne, stakeholderTitleTwo, stakeholderContentTwo, stakeholderTitleThree, stakeholderContentThree, stakeholderCta, marketingMainTitle, marketingSubtitle, marketingPone, marketingPtwo, marketingPthree, marketingLiOne, marketingLiTwo, marketingLiThree, marketingLiFour, marketingPfour, marketingCtaTitle, specialColor }: tabs) => {
  // here we want a grid of 4 divs that will be used to display the content of the tabs
  const [showing, setShowing] = useState(1);
  const [currentTab, setCurrentTab] = useState(1);

  const handleClickOne = () => {
    setShowing(1);
    setCurrentTab(1);
  };

  const handleClickTwo = () => {
    setShowing(2);
    setCurrentTab(2);
    
  };

  const handleClickThree = () => {
    setShowing(3);
    setCurrentTab(3);
  };


  return (
    <div className="relative bg-gradient-to-r from-base-100 to-neutral to-75% sm:py-24 lg:py-24" id="dashboards">
      <HeaderSection
    title="Jalapeño"
    titledos="Solutions"
    subtitle={tagline}
    charColor={specialColor}
    />

      <div className="mx-auto max-w-xl px-4  sm:max-w-2xl sm:px-2 lg:max-w-7xl lg:px-8">
        <div className="mt-12  sm:mt-16">
          <div className="grid grid-cols-3 gap-4 ">
            <div>
              <div
                onClick={handleClickOne}
                className="card text-neutral-content h-36 cursor-pointer"
                id = "tabOne"
                style={{
                  backgroundColor: currentTab === 1 ? "#4c0025   " : "#ffffff",
                }}
              >
                <div
                  className="lg:text-3xl select-none text-lg whitespace-nowrap font-medium card-body items-center text-center"
                  style={{ color: currentTab === 1 ? "#ffffff" : "#1a202c" }}
                >
                  {firstTabTitle.split(" ")[0]}
                  <div className="lg:text-3xl select-none text-lg font-medium text-white"
              style={{ color: currentTab === 1 ? "#ffffff" : "#1a202c" }}>{firstTabTitle.split(" ")[1]}</div>
                </div>
              </div>
            </div>
            <div
              onClick={handleClickTwo}
              id = "tabTwo"
              style={{
                backgroundColor: currentTab === 2 ? "#4c0025   " : "#ffffff",
              }}
              className="card cursor-pointer text-neutral-content"
            >
              <div
                className="lg:text-3xl select-none text-lg whitespace-nowrap font-medium card-body items-center text-center"
                style={{ color: currentTab === 2 ? "#ffffff" : "#1a202c" }}
              >
                {secondTabTitle.split(" ")[0]}
                <div className="lg:text-3xl text-lg  select-none font-medium text-white"
              style={{ color: currentTab === 2 ? "#ffffff" : "#1a202c" }}> {secondTabTitle.split(" ")[1]}</div>
              </div>
            </div>

            <div
              className="bg-white cursor-pointer  card text-neutral-content"
              onClick={handleClickThree}
              id = "tabThree"
              style={{backgroundColor: currentTab === 3 ? "#4c0025   " : "#ffffff"}}
            >
            <div
                className="lg:text-3xl select-none text-lg font-medium whitespace-nowrap card-body items-center text-center"
                style={{ color: currentTab === 3 ? "#ffffff" : "#1a202c" }}
              >
              {thirdTabTitle.split(" ")[0]}
              <div className="lg:text-3xl text-lg select-none font-medium text-white"
              style={{ color: currentTab === 3 ? "#ffffff" : "#1a202c" }}> {thirdTabTitle.split(" ")[1]}</div>
              </div>


            </div>
          </div>
        </div>
        <div className="py-12">
          {showing === 2 && (
            <div className="bg-white text-base font-medium text-white" id="contentTwo">
              <Contentexampledos 
              stakeholderMainTitle={stakeholderMainTitle}
              stakeholderSubtitle={stakeholderSubtitle}
              stakeholderTitleOne={stakeholderTitleOne}
              stakeholderContentOne={stakeholderContentOne}
              stakeholderTitleTwo={stakeholderTitleTwo}
              stakeholderContentTwo={stakeholderContentTwo}
              stakeholderTitleThree={stakeholderTitleThree}
              stakeholderContentThree={stakeholderContentThree}
              stakeholderCta={stakeholderCta}
              />
            </div>
          )}
          
          {showing === 3 &&  (
            
            <div className="bg-white text-base font-medium text-white">
              
              <Contentexampletres 
              marketingMainTitle = {marketingMainTitle}
              marketingSubtitle = {marketingSubtitle}
              marketingPone= {marketingPone}
              marketingPtwo= {marketingPtwo}
              marketingPthree= {marketingPthree}
              marketingPfour= {marketingPfour}
              marketingLiOne= {marketingLiOne}
              marketingLiTwo= {marketingLiTwo}
              marketingLiThree= {marketingLiThree}
              marketingLiFour= {marketingLiFour}
              marketingCtaTitle= {marketingCtaTitle}

              />
            </div>
          )}
          {showing === 1 && (
            <div className="bg-white text-base font-medium text-white">
              <Contentcuatro
              managementMainTitle={managementMainTitle}
              managementSubtitle={managementSubtitle}
              managementTitleOne={managementTitleOne}
              managementContentOne={managementContentOne}
              managementTitleTwo={managementTitleTwo}
              managementContentTwo={managementContentTwo}
              managementTitleThree={managementTitleThree}
              managementContentThree={managementContentThree}
              managementTitleFour={managementTitleFour}
              managementContentFour={managementContentFour}
              managementTitleFive={managementTitleFive}
              managementContentFive={managementContentFive}
              managementCtaTitle= {managementCtaTitle}
              managementCtaSubtitle= {managementCtaSubtitle}
              managementCta={managementCta}
              
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contenttabs;
