import React from 'react';
import NavNoLang from '../nav/navnolang';
import Footerdos from '../footer/footerdos';

interface PrivacyContentProps {
  title: string;
  content: string;
}

interface PrivacyProps {
  privacyTitle: string;
  privacyContent: PrivacyContentProps[];
}

const PrivacyComponent: React.FC<PrivacyProps> = ({ privacyTitle, privacyContent }) => {
  return (

    <> 
    <NavNoLang />
    <div className=' bg-gradient-to-r from-base-100 to-neutral to-75% '>
      <div className=''> 
      <div className='mx-auto font-bold text-primary max-w-7xl px-8 text-center'>
      <h1 className='text-3xl'>{privacyTitle}</h1>
      </div>
      <div className='mx-auto max-w-7xl text-left px-8 mt-10'> 
      {privacyContent.map((item, index) => (
        <ul className=''> 

        <li className='text-primary underline' key={index}> <a href={"#" + item.title.replace(" ", "-")}> {item.title}</a> </li>

        </ul>
      ))}
      </div>
      <div className='mx-auto  max-w-7xl px-8 text-left py-10 mt-10'>
      {privacyContent.map((item, index) => (
        <div key={index}>
          <h2 id={item.title.replace(" ", "-")} className='text-3xl text-primary font-bold text-left my-2'>{item.title}</h2>
          <p className='text-primary'>{item.content}</p>
        </div>
      ))}
      </div> 
    </div>
    
    </div>
    <Footerdos />
    </>
  );
};

export default PrivacyComponent;
