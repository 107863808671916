import dashboardmob from '../images/performancemob.webp'

export default function MobilePhone() {
  return (
    <div className="mockup-phone">
      <div className="camera"></div> 
      <div className="display overflow-scroll flex justify-center "> {/* Adjust the height as per your requirement */}
        <div className="artboard overflow-scroll  phone-3">
          <img src={dashboardmob} alt="description" className='mx-auto' />
        </div>
      </div>
    </div>
  )
}
