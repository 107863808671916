// we want to create 4 different divs that will be used to display the content of the tabs
import { useState } from "react";
import Contentexampledos from "../contentsection/contentexampledos";
import Contentexampletres from "../contentsection/contentexampletres";
import HeaderSection from "../headers/headersection";
import FirstTabContent from "./tabone";
import SecondTabContent from "./tabtwo";
import ThirdTabContent from "./tabthree";

type tabs = {
  tagline: string; 
  firstTabTitle: string;
  secondTabTitle: string;
  thirdTabTitle: string;
    firstTabMainTitle: string;
  firstTabSubtitle: string;
  firstTabTitleOne : string;
  firstTabContentOne : string;
  firstTabTitleTwo : string;
  firstTabContentTwo : string;
  firstTabTitleThree : string;
  firstTabContentThree : string;
  firstTabTitleFour : string;
  firstTabContentFour : string;
  firstTabTitleFive : string;
  firstTabContentFive : string;
    firstTabCtaTitle : string;
  firstTabCtaSubtitle : string;
  firstTabCta : string;
  firstTabHref: string; 
firstTabCtaTwo: string;
  
    tabThreeMainTitle: string;
  tabThreeSubtitle: string;
  tabThreeTitleOne : string;
  tabThreeContentOne : string;
  tabThreeTitleTwo : string;
  tabThreeContentTwo : string;
  tabThreeTitleThree : string;
  tabThreeContentThree : string;
    tabThreeTitleFour : string;
  tabThreeContentFour : string;
  tabThreeTitleFive : string;
    tabThreeContentFive : string;

  tabThreeCta : string;

      secondTabMainTitle: string;
  secondTabSubtitle: string;
  secondTabPone: string;
  secondTabPtwo: string;
  secondTabPthree: string;
  secondTabLiOne: string;
  secondTabLiTwo: string;
  secondTabLiThree: string;
  secondTabLiFour: string;
  secondTabLiFive: string; 
  secondTabPfour : string;
  secondTabCtaTitle : string;
  secondTabHref : string; 
      secondTabLiOnePara: string;
    secondTabLiTwoPara: string;
    secondTabLiThreePara: string;
    secondTabLiFourPara: string;
    secondTabLiFivePara: string;
  specialColor:  Boolean;



  
};

const ContentTabSection = ({tagline, firstTabTitle, secondTabTitle, thirdTabTitle, firstTabMainTitle, firstTabSubtitle, firstTabTitleOne, firstTabContentOne, firstTabTitleTwo, firstTabContentTwo, firstTabTitleThree, firstTabContentThree, firstTabTitleFour, firstTabContentFour, firstTabTitleFive, firstTabContentFive, firstTabCtaTitle, firstTabCtaSubtitle, firstTabCta, firstTabCtaTwo, firstTabHref,  tabThreeMainTitle, tabThreeSubtitle, tabThreeTitleOne, tabThreeContentOne, tabThreeTitleTwo, tabThreeContentTwo, tabThreeTitleThree, tabThreeContentThree, tabThreeTitleFour, tabThreeContentFour, tabThreeTitleFive, tabThreeContentFive, tabThreeCta, secondTabMainTitle, secondTabSubtitle, secondTabPone, secondTabPtwo, secondTabPthree, secondTabLiOne, secondTabLiTwo, secondTabLiThree, secondTabLiFour, secondTabLiFive, secondTabLiFivePara, secondTabPfour, secondTabCtaTitle, secondTabHref, secondTabLiOnePara, secondTabLiTwoPara, secondTabLiThreePara, secondTabLiFourPara, specialColor }: tabs) => {
  // here we want a grid of 4 divs that will be used to display the content of the tabs
  const [showing, setShowing] = useState(1);
  const [currentTab, setCurrentTab] = useState(1);

  const handleClickOne = () => {
    setShowing(1);
    setCurrentTab(1);
  };

  const handleClickTwo = () => {
    setShowing(2);
    setCurrentTab(2);
    
  };

  const handleClickThree = () => {
    setShowing(3);
    setCurrentTab(3);
  };


  return (
    <div className="relative bg-gradient-to-r from-base-100 to-neutral to-75% sm:py-24 lg:py-24" id="diensten">
      <HeaderSection
    title="Jalapeño"
    titledos="Solutions"
    subtitle={tagline}
    charColor={specialColor}
    />

      <div className="mx-auto max-w-xl px-4  sm:max-w-2xl sm:px-1 lg:max-w-7xl lg:px-8">
        <div className="mt-12  sm:mt-16">
          <div className="grid grid-cols-3 gap-4 ">
            <div>
              <div
                onClick={handleClickOne}
                className="card text-neutral-content h-36 cursor-pointer"
                id = "tabOne"
                style={{
                  backgroundColor: currentTab === 1 ? "#122738   " : "#ffffff",
                }}
              >
                <div
                  className="lg:text-3xl select-none text-lg whitespace-nowrap font-medium card-body items-center text-center"
                  style={{ color: currentTab === 1 ? "#ffffff" : "#1a202c" }}
                >
                  {firstTabTitle.split(" ")[0]}
                  <div className="lg:text-3xl text-lg whitespace-nowrap select-none  font-medium text-white"
              style={{ color: currentTab === 1 ? "#ffffff" : "#1a202c" }}>{firstTabTitle.split(" ")[1]}</div>
                </div>
              </div>
            </div>
            <div
              onClick={handleClickTwo}
              id = "tabTwo"
              style={{
                backgroundColor: currentTab === 2 ? "#122738   " : "#ffffff",
              }}
              className="card text-neutral-content cursor-pointer"
            >
              <div
                className="lg:text-3xl text-lg whitespace-nowrap select-none card-body font-medium  items-center text-center"
                style={{ color: currentTab === 2 ? "#ffffff" : "#1a202c" }}
              >
                {secondTabTitle.split(" ")[0]}
                <div className="lg:text-3xl text-lg whitespace-nowrap select-none font-medium text-white"
              style={{ color: currentTab === 2 ? "#ffffff" : "#1a202c" }}> {secondTabTitle.split(" ")[1]}</div>
              </div>
            </div>

            <div
              className="bg-white cursor-pointer card text-neutral-content"
              onClick={handleClickThree}
              id = "tabThree"
              style={{backgroundColor: currentTab === 3 ? "#122738   " : "#ffffff"}}
            >
            <div
                className="lg:text-3xl select-none text-lg whitespace-nowrap font-medium card-body items-center text-center"
                style={{ color: currentTab === 3 ? "#ffffff" : "#1a202c" }}
              >
              {thirdTabTitle.split(" ")[0]}
              <div className="lg:text-3xl text-lg whitespace-nowrap select-none font-medium text-white"
              style={{ color: currentTab === 3 ? "#ffffff" : "#1a202c" }}> {thirdTabTitle.split(" ")[1]}</div>
              </div>


            </div>
          </div>
        </div>
        <div className="py-12">
          {showing === 2 && (
            <div className="bg-white text-base font-medium text-white" id="contentTwo">
              <ThirdTabContent 
              tabThreeMainTitle={tabThreeMainTitle}
              tabThreeSubtitle={tabThreeSubtitle}
              tabThreeTitleOne={tabThreeTitleOne}
              tabThreeContentOne={tabThreeContentOne}
              tabThreeTitleTwo={tabThreeTitleTwo}
              tabThreeContentTwo={tabThreeContentTwo}
              tabThreeTitleThree={tabThreeTitleThree}
              tabThreeContentThree={tabThreeContentThree}
              tabThreeCta={tabThreeCta}
              tabThreeTitleFour={tabThreeTitleFour}
              tabThreeContentFour= {tabThreeContentFour}
              tabThreeTitleFive= {tabThreeTitleFive}
              tabThreeContentFive= {tabThreeContentFive}
              

              />
            </div>
          )}
          
          {showing === 3 &&  (
            
            <div className="bg-white text-base font-medium text-white">
              
              <SecondTabContent 
              secondTabMainTitle = {secondTabMainTitle}
              secondTabSubtitle = {secondTabSubtitle}
              secondTabPone= {secondTabPone}
              secondTabPtwo= {secondTabPtwo}
              secondTabPthree= {secondTabPthree}
              secondTabPfour= {secondTabPfour}
              secondTabLiOne= {secondTabLiOne}
              secondTabLiTwo= {secondTabLiTwo}
              secondTabLiThree= {secondTabLiThree}
              secondTabLiFour= {secondTabLiFour}
              secondTabLiFive= {secondTabLiFive}
              secondTabCtaTitle= {secondTabCtaTitle}
              secondTabHref = {secondTabHref}
                  secondTabLiOnePara = {secondTabLiOnePara}
                  secondTabLiTwoPara = {secondTabLiTwoPara}
                  secondTabLiThreePara= {secondTabLiThreePara}
                  secondTabLiFourPara= {secondTabLiFourPara}
                  secondTabLiFivePara= {secondTabLiFivePara}


              />
            </div>
          )}
          {showing === 1 && (
            <div className="bg-white text-base font-medium text-white">
              <FirstTabContent
              firstTabMainTitle={firstTabMainTitle}
              firstTabSubtitle={firstTabSubtitle}
              firstTabTitleOne={firstTabTitleOne}
              firstTabContentOne={firstTabContentOne}
              firstTabTitleTwo={firstTabTitleTwo}
              firstTabContentTwo={firstTabContentTwo}
              firstTabTitleThree={firstTabTitleThree}
              firstTabContentThree={firstTabContentThree}
              firstTabTitleFour={firstTabTitleFour}
              firstTabContentFour={firstTabContentFour}
              firstTabTitleFive={firstTabTitleFive}
              firstTabContentFive={firstTabContentFive}
              firstTabCtaTitle= {firstTabCtaTitle}
              firstTabCtaSubtitle= {firstTabCtaSubtitle}
              firstTabCta={firstTabCta}
              firstTabCtaTwo = {firstTabCtaTwo}
              firstTabHref={firstTabHref}
              
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentTabSection;
