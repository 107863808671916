import { DevicePhoneMobileIcon } from '@heroicons/react/24/outline';

 const nav = { social : [
    {
      name: 'LinkedIN',
      href: 'https://www.linkedin.com/company/jalapenomarketing/',
      target:'_blank',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Phone',
      href: 'tel:+31619307904',
      icon: (props) =>  <DevicePhoneMobileIcon
      
      {...props}
      />
    }
  ], 
 }

export default function MultilangFooter({ navigation }) {
  const phoneNumber = '+31619307904'; // Replace with your actual phone number

  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          {nav.social.map((item) => (
            <a key={item.name} href={item.href} target="_blank" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <div className="flex items-center">
                <item.icon className="h-6 w-6" aria-hidden="true" />
                {item.name === 'Phone' && <span className="ml-1 text-sm underline">{phoneNumber}</span>}
              </div>
            </a>
          ))}
        </div>
        <p className="mt-8 text-center text-base text-gray-400">&copy; 2023 Jalapeño Solutions. All rights reserved.</p>
      </div>
    </footer>
  );
}
