import React, { useState, useEffect } from 'react';
import NavNoLang from '../components/nav/navnolang';
import Footerdos from '../components/footer/footerdos';

interface Partner { 
    partnername: string; 
    partnerurl: string; 
}

const Partners: React.FC = () => {
  const [pages, setPages] = useState<Partner[]>([]);

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    const response = await fetch('https://jpswebapp-container-app.greentree-216e271f.westeurope.azurecontainerapps.io/partners/');
    const data = await response.json();
    setPages(data);
  };

  return (
    
    <div className=''>
        <NavNoLang /> 
    <div className='container bg-primary min-h-screen min-w-full'>
 <div className="mx-auto max-w-3xl text-base leading-7 text-base-100 pt-4">
    <title>Partners</title>
        <div className=''>
      <h2 className='mt-2 text-4xl font-bold tracking-tight  sm:text-4xl'>Partners</h2>
      <ul>
        {pages.map((page) => (
          <li key={page.partnername}>
            <a className='underline' href={page.partnerurl}>{page.partnername}</a>
          </li>
        ))}
      </ul>
    </div>
    </div>
    </div>
    <Footerdos />
    </div>
  );
};

export default Partners;
