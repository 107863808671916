import CenterCta from "../cta/centercta"

type contentProps = {
 firstTabMainTitle: string;
  firstTabSubtitle: string;
  firstTabTitleOne : string;
  firstTabContentOne : string;
  firstTabTitleTwo : string;
  firstTabContentTwo : string;
  firstTabTitleThree : string;
  firstTabContentThree : string;
  firstTabTitleFour : string;
  firstTabContentFour : string;
  firstTabTitleFive : string;
  firstTabContentFive : string;
  firstTabCtaTitle : string;
  firstTabCtaSubtitle : string;
  firstTabCta : string;
  firstTabHref : string; 
  firstTabCtaTwo: string;
}
export default function FirstTabContent( {firstTabMainTitle, firstTabSubtitle, firstTabTitleOne, firstTabContentOne, firstTabTitleTwo, firstTabContentTwo, firstTabTitleThree, firstTabContentThree, firstTabTitleFour, firstTabContentFour, firstTabTitleFive, firstTabContentFive, firstTabCtaTitle, firstTabCtaSubtitle, firstTabCta, firstTabCtaTwo, firstTabHref} : contentProps) {
  return (
    <div className="relative overflow-hidden bg-gray-50 py-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h2>
            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              {firstTabMainTitle}
            </span>
          </h2>
          <p className="mt-8 text-xl leading-8 text-neutral">
            {firstTabSubtitle}
          </p>
        </div>
        
        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-neutral">
                      <ul role="list">
            <li><a href="#besluitvorming" className="underline text-neutral cursor-pointer"> {firstTabTitleOne}</a></li>
            <li><a href="#transparantie" className="underline text-neutral cursor-pointer">{firstTabTitleTwo}</a></li>
            <li><a href="#efficientie" className="underline text-neutral cursor-pointer">{firstTabTitleThree}</a></li>
                        <li><a href="#samenwerking" className="underline text-neutral cursor-pointer">{firstTabTitleFour}</a></li>
            <li><a href="#tijdsbesparing" className="underline text-neutral">{firstTabTitleFive}</a></li>

          </ul>
            <h3 className="text-neutral" id="besluitvorming"><strong>{firstTabTitleOne}</strong></h3> 
          <p>
                              {firstTabContentOne}          </p>
                                              <div className="rounded-md shadow">
                  <a
                    href={'/' + firstTabHref}
                    className="flex no-underline w-full items-center justify-center rounded-md border border-transparent bg-neutral px-8 py-3 text-base font-medium text-white hover:bg-neutral md:py-4 md:px-10 md:text-lg"
                  >
                    {firstTabCta} 
                  </a>
                </div>

          <h3 className="text-neutral" id="transparantie"><strong> {firstTabTitleTwo}</strong></h3>
          <p>
            {firstTabContentTwo}

          </p>
          <h3 className="text-neutral" id="efficientie"><strong>{firstTabTitleThree}</strong></h3>
          <p>
            {firstTabContentThree}
          </p>
                    <h3 className="text-neutral" id="samenwerking"><strong>{firstTabTitleFour}</strong></h3>
          <p>
            {firstTabContentFour}
                      </p>
                              <h3 className="text-neutral" id="tijdsbesparing"><strong>{firstTabTitleFive}</strong></h3>
          <p>
{firstTabContentFive}
          </p>


                  <h2 className="text-3xl font-bold tracking-tight text-center text-neutral sm:text-4xl">
          <span className="block">{firstTabCtaTitle}</span>
        </h2>
        <p className="mt-4 text-lg leading-6 base-100">
          {firstTabCtaSubtitle}
        </p>
        <div> 
                <div className="rounded-md shadow">
                  <a
                    href={'/' + firstTabHref}
                    className="flex no-underline w-full items-center justify-center rounded-md border border-transparent bg-neutral px-8 py-3 text-base font-medium text-white hover:bg-neutral md:py-4 md:px-10 md:text-lg"
                  >
                    {firstTabCta} 
                  </a>
                </div>
                                <div className="rounded-md py-3">
                  <a
                    href='#contact'
                    className="flex no-underline w-full items-center justify-center rounded-md border border-transparent bg-base-100 px-8 py-3 text-base font-medium text-white hover:bg-neutral md:py-4 md:px-10 md:text-lg"
                  >
                    {firstTabCtaTwo} 
                  </a>
                </div>

                </div>
                

        </div>
        
      </div>
      
      
    </div>
  )
}
