
type headerProps = { 

    title: string; 
    subtitle: string;
    bigtitle?: string;
}

const HeaderSectionDark = ({title, subtitle, bigtitle} : headerProps) => {
    return (
        <div className=""> 
                <div className="">
      <div className="mx-auto max-w-7xl">
        <div className="text-center">
          <p className="mt-1 text-4xl italic font-bold tracking-tight text-base-100 sm:text-4xl lg:text-4xl">
            {title}
          </p>
                    <p className="mt-1 text-4xl italic font-bold tracking-tight text-base-100 sm:text-5xl lg:text-5xl">
            {subtitle}
          </p>
                              <p className="mt-1 text-4xl italic font-bold tracking-tight text-base-100 sm:text-5xl lg:text-6xl">
            {bigtitle}
          </p>


          <p className="mx-auto mt-5 max-w-xl text-xl hidden text-gray-500">
            {subtitle}
          </p>
        </div>
      </div>
    </div>

        </div>
    
    ); 
}


export default HeaderSectionDark