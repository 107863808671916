import React from 'react';
import CookieConsent from 'react-cookie-consent';
import './CookieNotification.css'; // Import CSS file for styling

const CookieNotification: React.FC = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Ik ga akkoord"
      flipButtons={true}
      enableDeclineButton={true}
      declineCookieValue="nee"
      cookieName="yourCookieName"
      style={{ background: '#333', color: '#fff', fontSize: '12px' }}
      buttonStyle={{ background: '#004C25', color: '#fff', fontSize: '12px'}}
      declineButtonStyle={{ background: '#fff', color: '#333', fontSize: '12px'}}
      declineButtonText="Niet akkoord"
      //debug={true}
      expires={180}
      
    >
      <p>
        We gebruiken cookies om uw ervaring op onze website te verbeteren. Cookies zijn bestanden die worden opgeslagen op het apparaat waarmee je onze website bezoekt. We verzamelen verschillende soorten cookies in onze systemen om analyses te kunnen verrichten op het bezoekersgedrag van deze website. Dit gaat om functionele cookies, analytische cookies en cookies voor marketingdoeleinden. Meer informatie over cookies en hoe je deze kunt uitschakelen vind je in onze{' '}
        <a href="/privacy-policy" className="text-blue-500">
          Privacy verklaring
        </a>
      </p>
    </CookieConsent>
  );
};

export default CookieNotification;
